<template>
  <v-app>
    <v-main class="container px-1">
      <h2 class="font-weight-light mb-2">
        {{ this.is_create ? 'Cadastrar Produto' : 'Alterar Produto' }}
      </h2>

      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
              v-model="product.name"
              label="Nome do Produto"
              required></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
              v-model="product.details"
              label="Descrição"></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
              v-model="product.price"
              v-mask="mask"
              label="Preço"></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn v-if="is_create"
                 color="primary"
                 @click="saveProduct">Salvar</v-btn>

          <v-btn v-if="is_edit"
                 color="primary"
                 @click="editProduct">Alterar</v-btn>

          <v-btn to="/produtos">Cancelar</v-btn>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  thousandsSeparatorSymbol: '.',
  allowNegative: false,
  decimalSymbol: ',',
  decimalLimit: 2
});

export default {
  name: "ProductForm",

  data() {
    return {
      submitted: false,
      mask: currencyMask,
      product: {
        id: null,
        name: null,
        details: null,
        price: null
      },
      is_create: true,
      is_edit: false,
    }
  },

  methods: {
    saveProduct() {
      const product = {
        name: this.product.name,
        details: this.product.details,
        price: this.product.price
      }

      console.log(product)

      axios.post('/api/product', product)
          .then(response => {
            this.product.id = response.data.id

            this.$router.push('/produtos')
          })
          .catch(e => {
            console.log(e)
          })
    },

    editProduct() {
      const product = {
        name: this.product.name,
        details: this.product.details,
        price: this.product.price,
        id: this.product.id,
      }

      axios.put('/api/product/edit', product)
          .then(response => {
            this.product.id = response.data.id

            this.$router.push('/produtos')
          })
          .catch(e => {
            console.log(e)
          })
    }
  },

  created() {
    if (Object.keys(this.$route.params).length == 0) {
      this.is_create = true
      this.is_edit = false

      return
    }

    this.is_create = false
    this.is_edit = true

    const params = this.$route.params

    this.product.id = params.id
    this.product.name = params.name
    this.product.details = params.details
    this.product.price = params.price
  }
}
</script>

<style scoped>

</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import About from '@/views/About'
import Product from "@/views/Product/Product";
import Login from "@/views/Login";
import ProductForm from "@/views/Product/components/ProductForm";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        meta: {
            auth: true
        },
        component: About
    },
    {
        path: '/produtos',
        name: 'Produtos',
        meta: {
            auth: true
        },
        component: Product
    },
    {
        path: '/produtos/adicionar',
        name: 'adicionarProduto',
        meta: {
            auth: true
        },
        component: ProductForm
    },
    {
        path: '/produtos/alterar',
        name: 'alterarProduto',
        meta: {
            auth: true
        },
        component: ProductForm
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem('user')

    if (to.matched.some(record => record.meta.auth) && !loggedIn) {
        next('/login')
        return
    }

    next()
})

export default router
<template>
  <v-form @submit.prevent="login">
    <h1>Login</h1>
    <v-container>
      <v-row>
        <v-col cols="12"
               md="4">
          <v-text-field label="E-mail"
                        required
                        v-model="email"></v-text-field>
        </v-col>

        <v-col cols="12"
               md="4">
          <v-text-field label="Password"
                        v-model="password"
                        required></v-text-field>
        </v-col>
        <v-btn elevation="2" type="submit">Login</v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: ''
    }
  },

  methods: {
    login() {
      this.$store
          .dispatch('login', {
            email: this.email,
            password: this.password
          })
          .then(() => {
            this.$router.push({name: 'About'})
          })
          .catch(err => {
            console.log(err)
          })
    }
  }
}
</script>
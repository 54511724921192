<template>
  <v-app>
    <v-main class="container px-1">
      <h2 class="font-weight-light mb-2">
        Produtos
      </h2>
      <v-row>
        <v-col>
          <v-btn to="produtos/adicionar"
                 color="primary">Novo</v-btn>
        </v-col>
      </v-row>
      <v-card>
        <v-data-table :headers="headers"
                      :items="items">
          <template v-slot:item.actions="{ item }">
            <v-icon small
                    class="mr-2"
                    @click="prepareEditItem(item)">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
import ProductForm from "@/views/Product/components/ProductForm"
import axios from 'axios'

export default {
  name: "Product",
  components: {ProductForm},
  data() {
    return {
      headers: [
        {text: 'Nº', value: 'id'},
        {text: 'Produto', value: 'name'},
        {text: 'Detalhes', value: 'details'},
        {text: 'Preço', value: 'price', name: 'price'},
        {text: 'Ações', value: 'actions', sortable: false},
      ],
      items: [],
      dialog: false, // used to toggle the dialog
      editedItem: {}, // empty holder for create/update ops
    }
  },

  methods: {
    getAllProducts() {
      axios.get('api/products')
          .then((response) => {
            this.items = response.data.products
      })
    },

    prepareEditItem(item) {
      console.log(item)
      this.$router.push({name: 'alterarProduto', params: item})
    }
  },
  mounted() {
    this.getAllProducts()
  }
}
</script>

<style scoped>

</style>